@charset "UTF-8";
/**
 * Clearfix
 */
@-webkit-viewport {
  width: device-width; }

@-moz-viewport {
  width: device-width; }

@-ms-viewport {
  width: device-width; }

@viewport {
  width: device-width; }

* {
  outline: none !important; }

a, button {
  transition: all .4s; }

input[type="button"], input[type="reset"], input[type="submit"] {
  transition: all .4s; }

strong, b {
  font-weight: 700; }

ul {
  -webkit-margin-before: 0em;
  -webkit-margin-after: 0em;
  -webkit-margin-start: 0px;
  -webkit-margin-end: 0px;
  -webkit-padding-start: 0px; }

html, body {
  width: 100%;
  height: 100%;
  position: relative;
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  line-height: 1.6;
  color: #434350; }

html {
  -webkit-font-smoothing: antialiased;
  font-size: 62.5%; }
  @media (max-width: 991px) {
    html {
      font-size: 60%; } }
  @media (max-width: 767px) {
    html {
      font-size: 60%; } }
  @media (max-width: 479px) {
    html {
      font-size: 57.5%; } }
  @media (min-width: 1200px) {
    html {
      font-size: 57.5%; } }

@media only screen and (min-width: 768px) {
  body {
    min-height: 700px; } }

body {
  width: 100%;
  position: relative;
  background-color: #fff;
  image-rendering: optimizeQuality;
  -ms-interpolation-mode: bicubic;
  text-rendering: optimizeLegibility;
  text-size-adjust: none;
  -moz-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  -ms-text-size-adjust: none;
  padding-top: 58px;
  font-size: 1.5rem; }
  @media only screen and (min-width: 768px) {
    body {
      padding-top: 106px; } }
  @media only screen and (min-width: 992px) {
    body {
      padding-top: 106px; } }
  @media only screen and (min-width: 768px) {
    body.home, body.collection, body.contact {
      padding-top: 0px; } }
  @media only screen and (min-width: 992px) {
    body.home, body.collection, body.contact {
      padding-top: 0px; } }

@media only screen and (min-width: 992px) {
  .row-eq-height {
    display: -ms-flexbox;
    display: flex; } }

.no-gutter {
  margin-left: 0 !important;
  margin-right: 0 !important; }

.no-gutter > [class*='col-'] {
  padding-right: 0;
  padding-left: 0; }

.wrapper {
  background: #fff;
  width: 100%;
  position: relative;
  z-index: 666;
  border-top: none; }
  @media only screen and (min-width: 768px) {
    .wrapper {
      border: 6px solid #fff;
      border-bottom: 0; } }
  .wrapper > .row {
    margin: 0px; }

.navbar-default {
  border: 0;
  transition: all .2s;
  border-top: 6px solid #bfe2ee;
  border-bottom: 0;
  background: #fff; }
  @media only screen and (min-width: 768px) {
    .navbar-default {
      padding-top: 6px; }
      .navbar-default:before {
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        border-bottom: 6px solid #fff; } }
  @media only screen and (min-width: 768px) {
    .navbar-default {
      margin: 0px 6px;
      width: calc(100% - 12px);
      background-color: rgba(255, 255, 255, 0.8); } }
  @media only screen and (min-width: 768px) {
    .navbar-default {
      min-height: 106px; } }
  .navbar-default .dropdown-header {
    font-weight: 700;
    display: block;
    padding: 3px 20px;
    font-size: 14px;
    text-transform: uppercase;
    line-height: 1.42857143;
    color: #000;
    white-space: nowrap;
    padding: 10px 15px;
    margin: 0; }
  @media only screen and (min-width: 992px) {
    .navbar-default .navbar-nav {
      margin-right: 14px; } }
  @media only screen and (min-width: 1200px) {
    .navbar-default .navbar-nav li {
      padding: 3.5rem 1rem; } }
  @media only screen and (min-width: 992px) {
    .navbar-default .navbar-nav li {
      position: relative;
      padding: 3rem 0.8rem; }
      .navbar-default .navbar-nav li:after {
        content: '•';
        width: 5px;
        height: 5px;
        position: absolute;
        top: 38px;
        left: 0; }
      .navbar-default .navbar-nav li:first-child:after, .navbar-default .navbar-nav li.no-sep:after {
        display: none; } }
  .navbar-default .navbar-nav li a {
    font-size: 1.4rem;
    text-transform: uppercase;
    font-family: 'Raleway', sans-serif;
    color: #283038;
    font-weight: 700;
    text-decoration: none; }
    @media only screen and (min-width: 992px) {
      .navbar-default .navbar-nav li a {
        padding: 1rem 1rem;
        letter-spacing: 1.1px;
        border-bottom: 2px solid transparent; } }
    @media only screen and (min-width: 1200px) {
      .navbar-default .navbar-nav li a {
        padding: 1rem 1.5rem; } }
    .navbar-default .navbar-nav li a:hover {
      color: #195EA9;
      border-bottom: 2px solid #3370B5; }
  .navbar-default .navbar-nav li.social, .navbar-default .navbar-nav li.search {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    width: 35%;
    float: left; }
  @media only screen and (min-width: 1200px) {
    .navbar-default .navbar-nav li.social {
      margin-left: 30px; } }
  .navbar-default .navbar-nav li.search {
    width: 65%; }
  @media only screen and (min-width: 992px) {
    .navbar-default .navbar-nav li.social, .navbar-default .navbar-nav li.search {
      width: auto;
      padding: 28px 1rem; } }
  .navbar-default .navbar-nav li.social:after {
    display: none; }
  .navbar-default .navbar-nav li.social a {
    padding: 0;
    float: left;
    width: 35px; }
    .navbar-default .navbar-nav li.social a:first-child {
      border-right: 1px solid rgba(151, 151, 151, 0.18); }
    .navbar-default .navbar-nav li.social a img {
      width: 35px; }
  .navbar-default .navbar-nav li.active > a, .navbar-default .navbar-nav li.active > a:hover, .navbar-default .navbar-nav li.active > a:focus {
    color: #195EA9 !important;
    background: none !important;
    border-bottom: 2px solid #3370B5; }
  .navbar-default .navbar-brand {
    color: #000;
    font-size: 1.2rem;
    letter-spacing: 3px;
    padding: 5px 15px; }
    .navbar-default .navbar-brand > img {
      width: auto;
      height: 100%; }
      @media only screen and (min-width: 768px) {
        .navbar-default .navbar-brand > img {
          margin-top: 9px;
          height: 65px; } }
      @media only screen and (min-width: 1200px) {
        .navbar-default .navbar-brand > img {
          height: 76px;
          margin-top: 0; } }
    @media only screen and (min-width: 768px) {
      .navbar-default .navbar-brand {
        padding: 10px 15px;
        margin-left: 0px !important; } }
    @media only screen and (min-width: 992px) {
      .navbar-default .navbar-brand {
        padding: 10px 0; } }
    .navbar-default .navbar-brand:hover {
      color: #000; }
  @media only screen and (min-width: 769px) and (max-width: 1150px) {
    .navbar-default .navbar-collapse {
      margin-right: 21px; } }
  .navbar-default .navbar-collapse .search_box {
    width: 100%;
    background-color: #fff;
    transition: all 0.2s;
    border: 1px solid #ededed; }
    .navbar-default .navbar-collapse .search_box form {
      margin: 0;
      height: 35px; }
    .navbar-default .navbar-collapse .search_box.active {
      width: 200px; }
      .navbar-default .navbar-collapse .search_box.active .form-control {
        background-position: 15px center; }
      @media only screen and (min-width: 769px) and (max-width: 1150px) {
        .navbar-default .navbar-collapse .search_box.active {
          width: 550px; } }
    .navbar-default .navbar-collapse .search_box .form-control {
      border: none;
      font-size: 12px;
      box-shadow: none;
      height: 24px;
      font-family: 'Raleway';
      font-weight: 400;
      font-size: 13px;
      color: #888;
      line-height: 22px;
      background: url("/img/icon-search.png") 12px center no-repeat;
      background-size: 12px;
      padding: 5px 15px 5px 35px;
      height: 35px; }
    @media only screen and (min-width: 992px) {
      .navbar-default .navbar-collapse .search_box {
        width: 35px;
        border: 0;
        border-radius: 85px;
        height: 35px; }
        .navbar-default .navbar-collapse .search_box .form-control {
          background-position: 12px center; } }
    @media only screen and (min-width: 769px) and (max-width: 1150px) {
      .navbar-default .navbar-collapse .search_box {
        position: fixed;
        right: 21px;
        height: 35px; } }
    .navbar-default .navbar-collapse .search_box ::-webkit-input-placeholder {
      /* WebKit, Blink, Edge */
      color: #C8C8C8; }
    .navbar-default .navbar-collapse .search_box :-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: #C8C8C8;
      opacity: 1; }
    .navbar-default .navbar-collapse .search_box ::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: #C8C8C8;
      opacity: 1; }
    .navbar-default .navbar-collapse .search_box :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #C8C8C8; }
  .navbar-default .navbar-toggle {
    border: 0;
    border-radius: 0;
    background: transparent; }
    @media only screen and (min-width: 768px) {
      .navbar-default .navbar-toggle {
        margin-top: 35px; } }
    .navbar-default .navbar-toggle > span {
      border-color: #000;
      border-radius: 0; }
    .navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus {
      background: transparent; }

.navbar-default .navbar-toggle {
  border-color: #000; }
  .navbar-default .navbar-toggle .icon-bar {
    height: 3px;
    background-color: #000; }

.navbar-default .navbar-collapse, .navbar-default .navbar-form {
  border-color: #000; }

.navbar-collapse {
  border: 0; }

footer {
  padding: 0.5rem 0;
  background: #bfe2ee;
  color: #1C1C1C;
  border-top: 6px solid #fff;
  font-size: 1.3rem;
  overflow: hidden;
  font-family: 'Raleway', sans-serif;
  position: relative;
  z-index: 888; }
  footer .container {
    width: 100%;
    padding: 0 4%; }
  @media only screen and (min-width: 768px) {
    footer {
      margin: 0px 6px;
      width: calc(100% - 12px); } }
  footer .fa {
    font-size: 13px;
    margin: 0px 5px; }
  footer a {
    color: #000;
    text-transform: uppercase;
    text-decoration: none; }
  footer .row {
    padding: 0.8rem 0;
    margin: 0; }
    footer .row .col:first-child {
      padding-right: 0; }
    footer .row .col:last-child {
      padding-left: 0; }
  footer ul.menu {
    margin: 0;
    padding: 0;
    list-style-type: none; }
    footer ul.menu li {
      display: inline-block;
      margin: 0;
      padding: 0 0.5rem;
      text-align: center;
      color: #C5B8BC; }
      footer ul.menu li.has-sep {
        border-right: 1px solid #C5B8BC;
        padding: 0 0.6rem; }
      footer ul.menu li.m-r {
        margin-right: 0.6rem; }
      footer ul.menu li a {
        color: #7e889d; }
        footer ul.menu li a:hover {
          color: #7e889d;
          text-decoration: underline; }
      footer ul.menu li.active a {
        color: #7e889d;
        text-decoration: underline; }
    footer ul.menu.hero {
      margin-top: -0.5rem; }
      footer ul.menu.hero li {
        font-family: 'Alice', serif;
        font-size: 1.6rem;
        line-height: 1;
        text-transform: capitalize;
        color: #fff;
        padding: 0 1rem; }
        footer ul.menu.hero li a {
          color: #fff; }
        footer ul.menu.hero li.active a {
          color: #141011; }

#latest-news {
  background-color: rgba(66, 91, 120, 0.7);
  z-index: 999;
  position: absolute;
  width: 180px;
  left: 50%;
  bottom: 0;
  margin-left: -90px; }
  @media only screen and (min-width: 768px) {
    #latest-news {
      position: fixed;
      width: 24rem;
      right: 15px;
      bottom: 0;
      left: auto;
      margin: 0; } }
  #latest-news.active {
    background-color: rgba(66, 91, 120, 0.9);
    position: absolute;
    right: 0;
    top: auto;
    bottom: 0;
    left: 0;
    width: 100%;
    margin: 0; }
    @media only screen and (min-width: 768px) {
      #latest-news.active {
        left: auto;
        width: 40rem; } }
    #latest-news.active img, #latest-news.active .swiper-news {
      display: block; }
    #latest-news.active h2 {
      text-align: left;
      padding: 0.5rem 1rem; }
      @media only screen and (min-width: 768px) {
        #latest-news.active h2 {
          padding: 0.8rem 1rem; } }
      #latest-news.active h2 .btn-toggle {
        right: 1rem;
        width: 22px;
        height: 22px;
        background: url("/img/icon-close.png") center right no-repeat;
        background-size: 22px;
        float: right; }
    #latest-news.active h3 {
      text-align: left;
      font-size: 2rem;
      padding: 1.5rem; }
      @media only screen and (min-width: 768px) {
        #latest-news.active h3 {
          font-size: 3rem;
          padding: 2rem; } }
    #latest-news.active .nav-arrows {
      display: block; }
  #latest-news h2 {
    cursor: pointer;
    margin: 0;
    padding: 0.8rem 0.5rem;
    letter-spacing: 2.7px;
    font-size: 1.3rem;
    color: #fff;
    line-height: 22px;
    text-transform: uppercase;
    text-align: center; }
    #latest-news h2 .btn-toggle {
      display: inline-block;
      width: 22px;
      height: 20px;
      background: url(/img/icon-arrow-up.png) center right no-repeat;
      background-size: 16px;
      position: absolute;
      right: 20px;
      transition: none; }
  #latest-news h3 {
    margin: 0;
    line-height: 1;
    padding: 1rem;
    line-height: 22px;
    font-size: 1.8rem;
    background: #153455;
    color: #fff;
    text-transform: uppercase;
    text-align: center; }
  #latest-news img {
    display: none; }
  #latest-news .swiper-news {
    background-color: #fff;
    display: none;
    margin: 0;
    width: 100%;
    height: auto; }
    #latest-news .swiper-news .swiper-wrapper {
      height: auto; }
    #latest-news .swiper-news .swiper-slide {
      height: auto; }
      #latest-news .swiper-news .swiper-slide img {
        width: 100%;
        height: auto; }
      #latest-news .swiper-news .swiper-slide .content {
        padding: 2rem 2.5rem; }
        #latest-news .swiper-news .swiper-slide .content a {
          color: rgba(0, 0, 0, 0.62); }
        #latest-news .swiper-news .swiper-slide .content h4 {
          font-family: 'Raleway';
          font-weight: 700;
          font-size: 2rem;
          color: #000000;
          letter-spacing: 1.57px;
          line-height: 1.4; }
        #latest-news .swiper-news .swiper-slide .content p {
          font-size: 1.3rem;
          font-family: 'Raleway', sans-serif;
          color: #1D1D1D;
          line-height: 1.6;
          margin-bottom: 15px; }
        #latest-news .swiper-news .swiper-slide .content a.read-more {
          /* Find out more: */
          font-family: 'Raleway';
          font-weight: bold;
          font-size: 1.5rem;
          color: #195EA9;
          float: left;
          background: url("/img/icon-read-more.png") left center no-repeat;
          background-size: 20px;
          padding-left: 30px;
          line-height: 25px;
          margin: 15px 0; }
        #latest-news .swiper-news .swiper-slide .content span.time {
          font-size: 1.3rem;
          font-family: "Raleway";
          color: rgba(131, 130, 130, 0.522);
          font-style: italic; }
        #latest-news .swiper-news .swiper-slide .content span.date {
          display: block;
          font-size: 1.3rem;
          font-family: "Raleway";
          color: #838282;
          margin: 0 0 10px 0; }
  #latest-news .nav-arrows {
    width: 100px;
    height: 35px;
    background: #fff;
    border-top: 1px solid rgba(151, 151, 151, 0.18);
    border-bottom: 1px solid rgba(151, 151, 151, 0.18);
    position: absolute;
    right: 2rem;
    bottom: 3rem;
    z-index: 666;
    display: none; }
    #latest-news .nav-arrows > a {
      display: block;
      float: left;
      width: 45px;
      height: 35px;
      background: url("/img/news-arrow-right.png") center no-repeat;
      background-size: 10px; }
      #latest-news .nav-arrows > a:first-child {
        border-right: 1px solid rgba(151, 151, 151, 0.18);
        background-image: url("/img/news-arrow-left.png"); }
      #latest-news .nav-arrows > a.swiper-button-disabled {
        opacity: 0.2; }

.white-popup {
  position: relative;
  background: #fff;
  padding: 20px;
  width: auto;
  max-width: 500px;
  margin: 20px auto; }
  @media only screen and (max-width: 768px) {
    .white-popup {
      max-width: 600px; } }

.mfp-zoom-out.mfp-bg {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  opacity: 0;
  transition: all 0.3s ease-out; }

.mfp-zoom-out.mfp-bg.mfp-ready {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
  opacity: 0.8; }

.mfp-zoom-out.mfp-bg.mfp-removing {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  opacity: 0; }

.mfp-zoom-out .mfp-content {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  opacity: 0;
  transition: all 0.3s ease-in-out;
  transform: scale(1.3); }

.mfp-zoom-out.mfp-ready .mfp-content {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100);
  opacity: 1;
  transform: scale(1); }

.mfp-zoom-out.mfp-removing .mfp-content {
  transform: scale(1.3);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  opacity: 0; }

.mfp-zoom-in {
  /* start state */
  /* animate in */
  /* animate out */ }
  .mfp-zoom-in .mfp-with-anim {
    opacity: 0;
    transition: all 0.2s ease-in-out;
    transform: scale(0.8); }
  .mfp-zoom-in.mfp-bg {
    opacity: 0;
    transition: all 0.3s ease-out; }
  .mfp-zoom-in.mfp-ready .mfp-with-anim {
    opacity: 1;
    transform: scale(1); }
  .mfp-zoom-in.mfp-ready.mfp-bg {
    opacity: 0.8; }
  .mfp-zoom-in.mfp-removing .mfp-with-anim {
    transform: scale(0.8);
    opacity: 0; }
  .mfp-zoom-in.mfp-removing.mfp-bg {
    opacity: 0; }

.swiper-pagination-bullet {
  background-color: #888; }

.swiper-pagination-bullet-active {
  background-color: #333; }

.home .wrapper {
  height: 100%; }
  @media only screen and (min-width: 768px) {
    .home .wrapper {
      border-bottom: 6px solid #fff; } }

@media only screen and (min-width: 768px) {
  .home footer {
    display: none; } }

.home .swiper-container {
  width: 100%;
  height: 100%;
  position: relative; }
  .home .swiper-container .swiper-slide {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 100%; }
    .home .swiper-container .swiper-slide img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center; }
  .home .swiper-container .swiper-pagination {
    bottom: 8rem; }
    @media only screen and (min-width: 768px) {
      .home .swiper-container .swiper-pagination {
        bottom: 5rem;
        width: auto;
        left: 5rem; } }
    @media only screen and (min-width: 1200px) {
      .home .swiper-container .swiper-pagination {
        left: 8rem; } }
    .home .swiper-container .swiper-pagination .swiper-pagination-bullet {
      margin: 0 15px;
      opacity: 1;
      position: relative; }
    .home .swiper-container .swiper-pagination .swiper-pagination-bullet-active:after {
      content: '';
      display: block;
      width: 22px;
      height: 22px;
      border-radius: 50%;
      border: 1px solid #fff;
      pointer-events: none;
      position: absolute;
      left: 50%;
      top: 50%;
      margin-left: -11px;
      margin-top: -11px; }

.collection .mfp-bg {
  opacity: 0.4;
  filter: alpha(opacity=40); }

@media only screen and (min-width: 768px) {
  .collection footer {
    display: none; } }

@media only screen and (min-width: 768px) {
  .collection .wrapper {
    height: 100%;
    border-bottom: 6px solid #fff; } }

.collection .wrapper .tooltip-point {
  width: 3rem;
  height: 3rem;
  position: absolute;
  z-index: 200;
  border: none;
  display: block;
  background: url("/img/icon-marker.svg") center no-repeat;
  background-size: 3rem;
  opacity: 0.4;
  transition: all 1s;
  transform: scale(0.95); }
  .collection .wrapper .tooltip-point:hover, .collection .wrapper .tooltip-point:active, .collection .wrapper .tooltip-point:focus, .collection .wrapper .tooltip-point.active {
    opacity: 1;
    transform: scale(1); }
  .collection .wrapper .tooltip-point.fade {
    opacity: 0; }
    .collection .wrapper .tooltip-point.fade:hover, .collection .wrapper .tooltip-point.fade:active, .collection .wrapper .tooltip-point.fade:focus, .collection .wrapper .tooltip-point.fade.active {
      opacity: 1; }

.collection .collect_nav {
  background: rgba(80, 119, 161, 0.75);
  display: block;
  width: 100%;
  position: relative; }
  @media only screen and (min-width: 992px) {
    .collection .collect_nav {
      position: fixed;
      width: 70px;
      left: 6px;
      bottom: 0;
      top: 114px;
      right: auto;
      z-index: 5;
      transition: all .5s;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
      align-items: center; } }
  @media only screen and (min-width: 1200px) {
    .collection .collect_nav {
      top: 106px; } }
  .collection .collect_nav ul {
    margin: 0;
    padding: 0;
    width: auto;
    list-style-type: none;
    white-space: nowrap;
    overflow-x: auto;
    text-align: center; }
    @media only screen and (min-width: 992px) {
      .collection .collect_nav ul {
        width: 100%; } }
    .collection .collect_nav ul li {
      text-align: center;
      list-style: none;
      width: 100px;
      display: block;
      float: left;
      width: 33.33333%;
      height: 65px; }
      @media only screen and (min-width: 768px) {
        .collection .collect_nav ul li {
          width: 16.66666%; } }
      @media only screen and (min-width: 992px) {
        .collection .collect_nav ul li {
          border-bottom: 1px solid #86929F;
          width: 100%;
          padding: 0;
          height: auto;
          float: none; } }
      .collection .collect_nav ul li:first-child {
        border-top: 1px solid #86929F; }
      .collection .collect_nav ul li a {
        padding: 10px 10px;
        display: block;
        width: 100%;
        display: block;
        text-decoration: none; }
        @media only screen and (min-width: 768px) {
          .collection .collect_nav ul li a {
            padding: 15px 0px;
            height: auto; } }
        .collection .collect_nav ul li a img {
          display: block;
          margin: 0 auto;
          padding-bottom: 5px;
          max-width: 30px;
          transition: all .4s;
          height: 30px; }
        .collection .collect_nav ul li a span {
          font-family: 'Raleway';
          font-weight: 300;
          font-size: 1rem;
          color: #FFFFFF;
          letter-spacing: 0.32px;
          line-height: 16px;
          display: block;
          transition: all .5s; }
          @media only screen and (min-width: 1200px) {
            .collection .collect_nav ul li a span {
              font-size: 1.1rem; } }
        @media only screen and (min-width: 992px) {
          .collection .collect_nav ul li a:hover {
            background-color: #3B454F; }
            .collection .collect_nav ul li a:hover span {
              font-weight: 500; }
            .collection .collect_nav ul li a:hover img {
              max-width: 35px;
              height: 35px; } }
      .collection .collect_nav ul li.active-nav-item {
        background: #3B454F; }
        .collection .collect_nav ul li.active-nav-item span {
          font-weight: 500; }

.collection .collection-items {
  margin: 15px 0; }
  .collection .collection-items a {
    display: block;
    margin-bottom: 15px; }
    .collection .collection-items a img {
      width: 100%; }

.collection .white-popup {
  border-radius: 0px;
  border: 0;
  padding: 10px;
  position: relative;
  background: #FFFFFF;
  max-width: 335px;
  box-shadow: 0px 11px 14px 0px rgba(0, 0, 0, 0.5); }
  @media (max-width: 767px) {
    .collection .white-popup {
      max-width: 100%;
      text-align: center; } }
  .collection .white-popup.static {
    box-shadow: none;
    margin: 0; }
  .collection .white-popup .mfp-close {
    background: #E40606;
    border: 1px solid #fff;
    color: #fff;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    font-weight: 300;
    font-family: Arial,Helvetica Neue,Helvetica,sans-serif;
    line-height: 25px;
    padding: 0;
    font-size: 22px;
    right: -11px;
    top: -11px;
    opacity: 1; }
  .collection .white-popup .img-preview {
    width: 100%;
    background: center no-repeat;
    background-size: cover;
    height: 23rem; }
  .collection .white-popup .collection-preview-numbers {
    display: none; }
    @media only screen and (min-width: 992px) {
      .collection .white-popup .collection-preview-numbers {
        display: block;
        font-size: 1.2rem;
        color: #000;
        margin: 0;
        text-align: right; }
        .collection .white-popup .collection-preview-numbers span {
          color: #000;
          font-size: 1.2rem; }
          .collection .white-popup .collection-preview-numbers span:first-child {
            font-size: 2rem; } }
  .collection .white-popup .swiper-preview {
    width: 100%;
    height: 23rem; }
    .collection .white-popup .swiper-preview .swiper-preview-button-next {
      position: absolute;
      width: 4rem;
      height: 4rem;
      background: url("/img/arrow-right-wide.png") center center no-repeat;
      background-size: 2rem;
      bottom: 0.5rem;
      right: 0;
      z-index: 666;
      cursor: pointer; }
    .collection .white-popup .swiper-preview .swiper-preview-button-prev {
      position: absolute;
      width: 4rem;
      height: 4rem;
      background: url("/img/arrow-left-wide.png") center center no-repeat;
      background-size: 2rem;
      bottom: 0.5rem;
      right: 5rem;
      margin-right: -1px;
      z-index: 666;
      cursor: pointer; }
    .collection .white-popup .swiper-preview .swiper-preview-button-prev.swiper-button-disabled, .collection .white-popup .swiper-preview .swiper-preview-button-next.swiper-button-disabled {
      opacity: 0; }
  .collection .white-popup .content {
    position: relative; }
    @media (max-width: 767px) {
      .collection .white-popup .content {
        margin-top: 2rem;
        text-align: left; } }
    @media only screen and (min-width: 768px) {
      .collection .white-popup .content {
        margin: 1rem; }
        .collection .white-popup .content .content-scroller {
          max-height: 18rem;
          overflow-y: auto; } }
    .collection .white-popup .content h4 {
      font-family: 'Raleway';
      font-weight: 700;
      font-size: 16px;
      color: #000000;
      letter-spacing: 1.25px;
      line-height: 1.4;
      margin-top: 0; }
    .collection .white-popup .content p {
      font-family: 'Raleway';
      font-size: 12px;
      color: #1D1D1D;
      line-height: 20px; }

.collection .swiper-container.collection-slider {
  width: 100%;
  height: 350px;
  position: relative; }
  @media (max-width: 480px) {
    .collection .swiper-container.collection-slider {
      height: 270px; } }
  @media only screen and (min-width: 992px) {
    .collection .swiper-container.collection-slider {
      height: 100%; } }
  .collection .swiper-container.collection-slider .swiper-slide {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 350px;
    position: relative; }
    @media (max-width: 480px) {
      .collection .swiper-container.collection-slider .swiper-slide {
        height: 270px; } }
    @media only screen and (min-width: 992px) {
      .collection .swiper-container.collection-slider .swiper-slide {
        height: 100%; }
        .collection .swiper-container.collection-slider .swiper-slide:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(0, 0, 0, 0.1);
          z-index: 2; } }
  .collection .swiper-container.collection-slider .swiper-button-next {
    width: 5rem;
    height: 7.5rem;
    background: transparent url("/img/arrow_right.svg") center center no-repeat;
    background-size: 30px;
    top: auto;
    bottom: 7.5rem;
    right: 0; }
    @media only screen and (min-width: 992px) {
      .collection .swiper-container.collection-slider .swiper-button-next {
        bottom: 5rem; } }
  .collection .swiper-container.collection-slider .swiper-button-prev {
    width: 5rem;
    height: 7.5rem;
    background: transparent url("/img/arrow_left.svg") center center no-repeat;
    background-size: 30px;
    top: auto;
    left: 0;
    bottom: 7.5rem;
    right: auto; }
    @media only screen and (min-width: 992px) {
      .collection .swiper-container.collection-slider .swiper-button-prev {
        left: auto;
        right: 12rem;
        bottom: 5rem; } }
  .collection .swiper-container.collection-slider .collection-numbers {
    display: none; }
    @media only screen and (min-width: 992px) {
      .collection .swiper-container.collection-slider .collection-numbers {
        display: block;
        position: absolute;
        z-index: 2;
        right: 5rem;
        bottom: 7rem;
        font-size: 1.5rem;
        color: #fff;
        text-align: center;
        width: 7rem; }
        .collection .swiper-container.collection-slider .collection-numbers span {
          color: #fff;
          font-size: 1.5rem; }
          .collection .swiper-container.collection-slider .collection-numbers span:first-child {
            font-size: 2.2rem; } }
  .collection .swiper-container.collection-slider .swiper-button-prev.swiper-button-disabled, .collection .swiper-container.collection-slider .swiper-button-next.swiper-button-disabled {
    opacity: 0.4; }

.news-press .navbar-default {
  background: #fff; }
  @media only screen and (min-width: 768px) {
    .news-press .navbar-default {
      background-color: #fff; } }
  .news-press .navbar-default .search_box {
    border: 1px solid #ededed; }

@media only screen and (min-width: 768px) {
  .news-press .wrapper {
    height: 100%; }
    .news-press .wrapper > .row {
      height: 100%; } }

.news-press .swiper-news-preview .swiper-pagination-bullet {
  background-color: #ededed; }

.news-press .swiper-news-preview .swiper-pagination-bullet-active {
  background-color: #fff; }

.news-press .news-list {
  padding: 1.5rem; }
  .news-press .news-list h2 {
    font-size: 1.6rem;
    color: #486B7E;
    letter-spacing: 1.25px;
    line-height: 35px;
    float: left;
    margin: 0 1.5rem; }
  .news-press .news-list .nav-arrows {
    width: 100px;
    height: 35px;
    background: #fff;
    border-top: 1px solid rgba(151, 151, 151, 0.18);
    border-bottom: 1px solid rgba(151, 151, 151, 0.18);
    float: right;
    margin: 0 1.5rem; }
    .news-press .news-list .nav-arrows > a {
      display: block;
      float: left;
      width: 45px;
      height: 35px;
      background: url("/img/news-arrow-right.png") center no-repeat;
      background-size: 10px; }
      .news-press .news-list .nav-arrows > a:first-child {
        border-right: 1px solid rgba(151, 151, 151, 0.18);
        background-image: url("/img/news-arrow-left.png"); }
      .news-press .news-list .nav-arrows > a.swiper-button-disabled {
        opacity: 0.2; }
  .news-press .news-list .swiper-news-list {
    margin: 1.5rem 0;
    padding: 0;
    list-style-type: none;
    height: 70vh;
    min-height: 500px; }
    @media only screen and (min-height: 850px) {
      .news-press .news-list .swiper-news-list {
        margin: 3rem 0; } }
    .news-press .news-list .swiper-news-list .swiper-slide {
      padding: 2rem 1.5rem;
      border-bottom: 1px solid rgba(191, 208, 216, 0.6);
      background-color: #fff; }
      .news-press .news-list .swiper-news-list .swiper-slide:hover, .news-press .news-list .swiper-news-list .swiper-slide.active {
        background-color: #f9f9f9; }
        .news-press .news-list .swiper-news-list .swiper-slide:hover h4, .news-press .news-list .swiper-news-list .swiper-slide.active h4 {
          color: #195EA9; }
    .news-press .news-list .swiper-news-list .news-item {
      text-decoration: none;
      display: block; }
      .news-press .news-list .swiper-news-list .news-item h4 {
        font-family: 'Raleway';
        font-weight: 700;
        font-size: 1.6rem;
        color: #000000;
        letter-spacing: 1.57px;
        line-height: 1.3;
        text-transform: capitalize;
        margin-top: 0;
        margin-bottom: 0.5rem; }
      .news-press .news-list .swiper-news-list .news-item p {
        font-size: 1.35rem;
        font-family: 'Raleway', sans-serif;
        color: #1D1D1D;
        line-height: 1.6;
        margin-bottom: 15px; }
        @media only screen and (min-width: 1680px) {
          .news-press .news-list .swiper-news-list .news-item p {
            font-size: 1.4rem; } }
      .news-press .news-list .swiper-news-list .news-item .read-more {
        font-family: "Raleway";
        font-weight: 700;
        color: #195EA9;
        line-height: 16px; }
      .news-press .news-list .swiper-news-list .news-item span.time {
        font-size: 1.3rem;
        font-family: "Raleway";
        color: rgba(131, 130, 130, 0.522);
        font-style: italic; }
      .news-press .news-list .swiper-news-list .news-item span.date {
        display: block;
        font-size: 1.3rem;
        line-height: 1;
        font-family: "Raleway";
        color: #838282;
        margin: 0 0 5px 0; }

.news-press .news-featured {
  position: relative; }
  @media only screen and (min-width: 992px) {
    .news-press .news-featured {
      height: 100%; } }
  .news-press .news-featured img {
    width: 100%; }
  .news-press .news-featured .swiper-news-preview {
    height: 300px; }
    @media only screen and (min-width: 992px) {
      .news-press .news-featured .swiper-news-preview {
        height: 60%; } }
    @media only screen and (min-width: 1200px) {
      .news-press .news-featured .swiper-news-preview {
        height: 70%; } }
  .news-press .news-featured .swiper-news-preview .swiper-wrapper, .news-press .news-featured .swiper-news-preview .swiper-slide {
    height: 100%; }
  .news-press .news-featured .image-featured {
    height: 100%;
    width: 100%;
    background: #fff center no-repeat;
    background-size: cover; }
  .news-press .news-featured .content {
    background: url("/img/news-bg.jpg") center no-repeat;
    background-size: cover;
    padding: 2.5rem; }
    @media only screen and (min-width: 992px) {
      .news-press .news-featured .content {
        height: 40%; } }
    @media only screen and (min-width: 1200px) {
      .news-press .news-featured .content {
        height: 30%;
        padding: 2.5rem; } }
    .news-press .news-featured .content h2 {
      font-size: 3rem;
      color: #FFFFFF;
      line-height: 1.4;
      margin-top: 0;
      text-transform: capitalize; }
    .news-press .news-featured .content p {
      font-size: 1.4rem;
      color: #FFFFFF;
      line-height: 1.8; }
    .news-press .news-featured .content .btn {
      margin-top: 15px;
      border-radius: 0;
      box-shadow: none;
      background: #C8DFEB;
      font-weight: 700;
      font-size: 1.3rem;
      color: #1C426A;
      letter-spacing: 1px;
      line-height: 4rem;
      height: 4rem;
      padding: 0 20px; }
  .news-press .news-featured .preloader {
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    text-align: center;
    position: absolute;
    z-index: 999;
    left: 0;
    top: 0;
    cursor: pointer;
    display: none; }
    .news-press .news-featured .preloader span {
      position: absolute;
      left: 50%;
      top: 50%;
      width: 200px;
      height: 50px;
      margin-top: -25px;
      margin-left: -100px;
      text-align: center;
      font-size: 16px;
      color: #fff;
      text-transform: uppercase; }

.news-press .widgets {
  padding: 1.5rem; }
  @media only screen and (min-width: 992px) {
    .news-press .widgets {
      padding-top: 0;
      padding-bottom: 0;
      margin-left: 0 !important;
      margin-right: 0 !important; }
      .news-press .widgets > [class*='col-'] {
        padding-right: 0;
        padding-left: 0; } }
  .news-press .widgets h4 {
    background: #030303;
    padding: 1rem 0.5rem;
    display: block;
    text-align: center;
    font-size: 1.5rem;
    color: #fff;
    margin: 15px 0 0 0; }
    @media only screen and (min-width: 768px) {
      .news-press .widgets h4 {
        margin: 0; } }
    @media only screen and (min-width: 992px) {
      .news-press .widgets h4 {
        margin: 15px 0 0 0; } }
    .news-press .widgets h4 a {
      color: #fff;
      display: block; }
    .news-press .widgets h4 .fa {
      margin-right: 5px; }
  .news-press .widgets .fb-page {
    width: 100%; }
  .news-press .widgets .instagram {
    width: 100%;
    position: relative;
    padding-bottom: 75%;
    height: 0;
    overflow: hidden;
    margin-top: 5px; }
    .news-press .widgets .instagram iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
  .news-press .widgets .sbi_follow_btn a {
    display: -moz-inline-stack;
    display: inline-block;
    vertical-align: top;
    zoom: 1;
    padding: 7px 14px;
    margin: 5px auto 0;
    background: #333;
    border: none;
    color: #fff;
    text-decoration: none;
    font-size: 13px;
    line-height: 1.5;
    border-radius: 4px;
    box-sizing: border-box; }
  .news-press .widgets .sbi_follow_btn {
    display: block;
    margin-top: 15px;
    text-align: center; }
  .news-press .widgets .sbi_follow_btn a {
    background: #517fa4;
    color: #fff; }
  .news-press .widgets .sbi_follow_btn a:focus,
  .news-press .widgets .sbi_follow_btn a:hover {
    filter: alpha(opacity=85);
    opacity: .85; }
  .news-press .widgets .sbi_follow_btn .fa {
    margin-bottom: -1px;
    margin-right: 7px;
    font-size: 15px; }
  .news-press .widgets .sbi_follow_btn {
    margin-left: 5px; }

.news-press .top-scroll {
  width: 50px;
  height: 50px;
  background-color: #bfe2ee;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  position: fixed;
  bottom: 50px;
  right: 20px;
  z-index: 9999;
  opacity: 0; }
  .news-press .top-scroll img {
    width: 100%;
    max-width: 10px;
    transform: rotate(270deg); }

.news-press .show-tpBtn {
  opacity: 1; }

.about .navbar-default {
  background: #fff; }
  @media only screen and (min-width: 768px) {
    .about .navbar-default {
      background-color: #fff; } }
  .about .navbar-default .search_box {
    border: 1px solid #ededed; }

.about .wrapper {
  background: #FAFBFB; }
  .about .wrapper > .row {
    position: relative;
    height: 100%; }

.about .arrow-up, .about .arrow-down {
  display: none; }

@media only screen and (min-width: 992px) {
  .about .arrow-up, .about .arrow-down {
    display: block;
    width: 16.6667%;
    height: 3rem;
    background-color: red;
    position: fixed;
    z-index: 777; }
  .about .arrow-up {
    background: url("/img/arrow-up-wide.png") center no-repeat;
    background-size: 5rem;
    top: 112px;
    right: 0;
    margin-top: 1rem; }
  .about .arrow-down {
    background: url("/img/arrow-down-wide.png") center no-repeat;
    background-size: 5rem;
    bottom: 0;
    margin-bottom: 1rem;
    right: 0; } }

.about .about-pic {
  background: url("/img/about_main.png") center bottom no-repeat;
  background-size: cover;
  height: 60vh; }
  @media only screen and (min-width: 768px) {
    .about .about-pic {
      z-index: 1;
      position: fixed;
      left: 6px;
      top: 112px;
      width: 50%;
      bottom: 0;
      height: auto; } }
  @media only screen and (min-width: 992px) {
    .about .about-pic {
      width: 33.33333333%; } }

.about .about_contents {
  padding: 30px 15px; }
  @media only screen and (min-width: 992px) {
    .about .about_contents {
      text-align: justify; } }
  @media only screen and (min-width: 768px) {
    .about .about_contents {
      margin-left: 50%;
      padding: 3rem 3rem; } }
  @media only screen and (min-width: 992px) {
    .about .about_contents {
      margin-left: 33.33333333%;
      padding: 6rem 8rem; } }
  .about .about_contents h2 {
    color: #567EAA;
    padding-bottom: 20px;
    font-family: 'Raleway';
    font-weight: 700;
    font-size: 3rem;
    color: #090909;
    line-height: 1.6;
    margin-top: 0; }
  .about .about_contents h4 {
    font-size: 2rem;
    color: #567EAA;
    border-top: 1px solid #ebecec;
    padding-top: 2.3rem;
    font-weight: 800; }
  .about .about_contents h4:first-of-type {
    border-top: 0;
    padding-top: 0; }
  .about .about_contents p {
    font-size: 1.6rem;
    font-family: 'Raleway', sans-serif;
    color: #373431;
    line-height: 1.8;
    margin-bottom: 1.8rem;
    font-weight: 300; }
  .about .about_contents .images-slider {
    width: 100%;
    margin: 30px 0; }
    .about .about_contents .images-slider a.images-slider-item {
      display: block;
      transition: opacity 0.2s; }
      .about .about_contents .images-slider a.images-slider-item:hover {
        opacity: 0.8; }
    .about .about_contents .images-slider .swiper-pagination {
      bottom: auto;
      left: auto;
      width: 100%;
      position: relative;
      margin-top: 15px; }
  .about .about_contents .go_back {
    background: url("/img/back-arrow.png") center left no-repeat;
    font-size: 13px;
    color: #3a6292;
    padding: 0px 0px 0px 20px;
    float: left; }
    .about .about_contents .go_back:hover {
      color: #50739e; }
  .about .about_contents .bread_crumb {
    text-align: right;
    width: 100%;
    font-size: 11px;
    font-family: "Lato";
    color: #000; }
    .about .about_contents .bread_crumb a {
      color: #b5bfc9; }

.about .about_logos {
  background: #fff;
  padding: 1.5rem; }
  @media only screen and (min-width: 768px) {
    .about .about_logos {
      padding: 3rem; } }
  @media only screen and (min-width: 992px) {
    .about .about_logos {
      padding: 0 10px;
      z-index: 100;
      position: fixed;
      right: 0;
      top: 112px;
      width: 16.6667%;
      bottom: 0;
      height: auto;
      overflow-y: scroll; } }
  .about .about_logos ul {
    padding: 0px;
    margin: 30px 0;
    width: 100%; }
    @media only screen and (min-width: 992px) {
      .about .about_logos ul {
        height: 100%; } }
    .about .about_logos ul li {
      text-align: center;
      padding: 5px;
      margin: 0;
      display: inline-block;
      background: #fff;
      width: 50%;
      float: left; }
      @media only screen and (min-width: 992px) {
        .about .about_logos ul li {
          padding: 15px 10px;
          width: 100%;
          display: block;
          float: none; } }
      .about .about_logos ul li a {
        display: block;
        box-shadow: 0 0 15px transparent;
        padding: 10px 0;
        text-align: center;
        margin-bottom: 5px; }
        .about .about_logos ul li a img {
          display: block;
          margin: 0 auto;
          opacity: 0.4;
          transition: all .5s;
          max-width: 160px; }
        .about .about_logos ul li a:hover, .about .about_logos ul li a.active {
          background: #fff;
          box-shadow: 0 0 15px rgba(0, 0, 0, 0.2); }
          .about .about_logos ul li a:hover img, .about .about_logos ul li a.active img {
            opacity: 1; }

.partners .navbar-default {
  background: #fff; }
  @media only screen and (min-width: 768px) {
    .partners .navbar-default {
      background-color: #fff; } }

.partners .wrapper {
  background: #FAFBFB; }

.partners .arrow-up, .partners .arrow-down {
  display: none; }

@media only screen and (min-width: 992px) {
  .partners .arrow-up, .partners .arrow-down {
    display: block;
    width: 16.6667%;
    height: 3rem;
    background-color: red;
    position: fixed;
    z-index: 777; }
  .partners .arrow-up {
    background: url("/img/arrow-up-wide.png") center no-repeat;
    background-size: 5rem;
    top: 112px;
    right: 0;
    margin-top: 1rem; }
  .partners .arrow-down {
    background: url("/img/arrow-down-wide.png") center no-repeat;
    background-size: 5rem;
    bottom: 0;
    margin-bottom: 1rem;
    right: 0; } }

.partners .partner-pic {
  background: url("/img/about_main.png") center bottom no-repeat;
  background-size: cover;
  height: 60vh; }
  @media only screen and (min-width: 768px) {
    .partners .partner-pic {
      z-index: 1;
      position: fixed;
      left: 6px;
      top: 112px;
      width: 50%;
      bottom: 0;
      height: auto; } }
  @media only screen and (min-width: 992px) {
    .partners .partner-pic {
      width: 33.33333333%; } }

.partners .partner_contents {
  padding: 30px 15px; }
  @media only screen and (min-width: 992px) {
    .partners .partner_contents {
      text-align: justify; } }
  @media only screen and (min-width: 768px) {
    .partners .partner_contents {
      margin-left: 50%;
      padding: 3rem 3rem; } }
  @media only screen and (min-width: 992px) {
    .partners .partner_contents {
      margin-left: 33.33333333%;
      padding: 6rem 8rem; } }
  .partners .partner_contents h2 {
    color: #567EAA;
    margin: 0;
    font-family: 'Raleway';
    font-weight: 700;
    font-size: 3rem;
    color: #090909;
    line-height: 24px;
    margin-bottom: 30px; }
  .partners .partner_contents h4 {
    font-size: 2rem;
    color: #567EAA;
    border-top: 1px solid #ebecec;
    padding-top: 2.3rem;
    font-weight: 800; }
  .partners .partner_contents h4:first-of-type {
    border-top: 0;
    padding-top: 0; }
  .partners .partner_contents p {
    font-size: 1.6rem;
    font-family: 'Raleway', sans-serif;
    color: #373431;
    line-height: 1.8;
    margin-bottom: 1.8rem;
    font-weight: 300; }
  .partners .partner_contents .images-slider {
    width: 100%;
    margin: 30px 0; }
    .partners .partner_contents .images-slider a.images-slider-item {
      display: block;
      transition: opacity 0.2s; }
      .partners .partner_contents .images-slider a.images-slider-item:hover {
        opacity: 0.8; }
    .partners .partner_contents .images-slider .swiper-pagination {
      bottom: auto;
      left: auto;
      width: 100%;
      position: relative;
      margin-top: 15px; }
  .partners .partner_contents .go_back {
    background-size: 20px;
    padding: 0px 0px 0px 20px;
    font-family: 'Raleway';
    font-weight: 700;
    font-size: 13px;
    color: #195EA9;
    line-height: 24px;
    margin-bottom: 30px; }
    .partners .partner_contents .go_back:hover {
      color: #50739e; }
    .partners .partner_contents .go_back a {
      display: inline-block;
      line-height: 22px; }
    .partners .partner_contents .go_back img {
      margin-right: 5px;
      height: 22px;
      display: inline-block; }
    .partners .partner_contents .go_back span {
      display: inline-block;
      line-height: 22px; }
  .partners .partner_contents .bread_crumb {
    text-align: right;
    width: 100%;
    font-size: 11px;
    font-family: "Lato";
    color: #000; }
    .partners .partner_contents .bread_crumb a {
      color: #b5bfc9; }

.partners .about_logos {
  background: #fff;
  padding: 1.5rem; }
  .partners .about_logos .arrow-up, .partners .about_logos .arrow-down {
    display: none; }
  @media only screen and (min-width: 768px) {
    .partners .about_logos {
      padding: 3rem; }
      .partners .about_logos .arrow-up, .partners .about_logos .arrow-down {
        display: block;
        width: 5rem;
        height: 3rem;
        background-color: red;
        position: fixed;
        z-index: 777; }
      .partners .about_logos .arrow-up {
        top: 112px;
        right: 0;
        margin-top: 1rem; }
      .partners .about_logos .arrow-down {
        bottom: 0;
        margin-bottom: 1rem;
        right: 0; } }
  @media only screen and (min-width: 992px) {
    .partners .about_logos {
      padding: 0 10px;
      z-index: 100;
      position: fixed;
      right: 0;
      top: 112px;
      width: 16.6667%;
      bottom: 0;
      height: auto;
      overflow-y: scroll; } }
  .partners .about_logos ul {
    padding: 0px;
    margin: 30px 0;
    width: 100%; }
    @media only screen and (min-width: 992px) {
      .partners .about_logos ul {
        height: 100%; } }
    .partners .about_logos ul li {
      text-align: center;
      padding: 5px;
      margin: 0;
      display: inline-block;
      background: #fff;
      width: 50%;
      float: left; }
      @media only screen and (min-width: 992px) {
        .partners .about_logos ul li {
          padding: 15px 10px;
          width: 100%;
          display: block;
          float: none; } }
      .partners .about_logos ul li a {
        display: block;
        box-shadow: 0 0 15px transparent;
        padding: 10px 0;
        text-align: center;
        margin-bottom: 5px; }
        .partners .about_logos ul li a img {
          display: block;
          margin: 0 auto;
          opacity: 0.4;
          transition: all .5s;
          max-width: 160px; }
        .partners .about_logos ul li a:hover, .partners .about_logos ul li a.active {
          background: #fff;
          box-shadow: 0 0 15px rgba(0, 0, 0, 0.2); }
          .partners .about_logos ul li a:hover img, .partners .about_logos ul li a.active img {
            opacity: 1; }

@media only screen and (min-width: 768px) {
  .contact .container {
    height: 100%; } }

.contact .wrapper {
  background: #fff url("/img/contact_bg.jpg") center center no-repeat;
  background-size: cover;
  padding: 5rem 1.5rem;
  position: relative; }
  @media only screen and (min-width: 768px) {
    .contact .wrapper {
      height: 100%;
      padding: 156px 3rem 50px; } }
  @media only screen and (min-width: 1200px) {
    .contact .wrapper {
      padding: 156px 12rem 50px; } }
  .contact .wrapper:after {
    content: '';
    position: fixed;
    left: 0px;
    right: 0px;
    top: 0;
    bottom: 0;
    opacity: 0.8;
    background: rgba(0, 0, 0, 0.4);
    z-index: 0; }
    @media only screen and (min-width: 768px) {
      .contact .wrapper:after {
        left: 6px;
        right: 6px;
        top: 114px; } }
    @media only screen and (min-width: 992px) {
      .contact .wrapper:after {
        top: 114px; } }
    @media only screen and (min-width: 1200px) {
      .contact .wrapper:after {
        top: 106px; } }

.contact .google-maps {
  position: relative;
  height: 300px;
  width: 100%;
  overflow: hidden;
  z-index: 55;
  margin-top: 15px; }
  .contact .google-maps a.link-direction {
    display: block;
    width: 5rem;
    height: 5rem;
    background: url("/img/icon-direction.png") center center no-repeat;
    background-size: 5rem;
    position: absolute;
    left: 3rem;
    top: 3rem;
    z-index: 777; }
  @media only screen and (min-width: 992px) {
    .contact .google-maps {
      height: 100%;
      margin-top: 0; } }

.contact #map {
  width: 100% !important;
  height: 100%; }
  @media only screen and (min-width: 992px) {
    .contact #map {
      margin-top: 0;
      position: absolute;
      top: 0;
      left: 0;
      height: 100% !important; } }

.contact .contact-info {
  position: relative;
  width: 100%;
  background: rgba(251, 251, 251, 0.95);
  box-shadow: 0px 5px 9px 0px rgba(0, 0, 0, 0.5);
  z-index: 66;
  padding: 30px; }
  @media only screen and (min-width: 992px) {
    .contact .contact-info {
      position: absolute;
      right: 3rem;
      top: 156px;
      bottom: 50px;
      max-width: 55rem;
      padding: 4rem 4rem;
      margin: 3rem 10rem; } }
  @media only screen and (min-width: 1200px) {
    .contact .contact-info {
      right: 12rem; } }
  @media only screen and (min-height: 850px) {
    .contact .contact-info {
      max-width: 60rem;
      padding: 7rem 5rem; } }
  .contact .contact-info h2 {
    display: block;
    text-align: center;
    font-size: 2rem;
    font-family: 'Raleway';
    font-weight: 700;
    color: #2A4953;
    line-height: 1.1;
    margin: 0;
    border-bottom: 1px solid rgba(151, 151, 151, 0.12);
    padding-bottom: 10px;
    margin-bottom: 10px; }
    @media only screen and (min-width: 768px) {
      .contact .contact-info h2 {
        font-size: 2.8rem; } }
    @media only screen and (min-height: 850px) {
      .contact .contact-info h2 {
        font-size: 3.4rem;
        line-height: 1.3;
        max-width: 80%;
        margin: 0 auto;
        margin-bottom: 15px; } }
  .contact .contact-info p {
    font-size: 1.4rem;
    font-family: 'Raleway', sans-serif;
    color: #1D1D1D;
    line-height: 2;
    padding-bottom: 20px;
    font-weight: 300; }
  .contact .contact-info .opening-info {
    margin-top: 10px;
    background: rgba(190, 226, 238, 0.47); }
    @media only screen and (min-height: 850px) {
      .contact .contact-info .opening-info {
        margin-top: 20px; } }
    .contact .contact-info .opening-info img {
      width: 100%; }
    .contact .contact-info .opening-info p {
      padding: 5px 15px;
      font-size: 1.4rem;
      color: #1D1D1D;
      line-height: 1.5;
      margin-bottom: 0; }
      .contact .contact-info .opening-info p strong {
        font-size: 1.6rem;
        color: #000000;
        line-height: 2; }
  .contact .contact-info .contact_nos {
    display: table; }
    .contact .contact-info .contact_nos .contact_icons {
      display: table-cell; }
    .contact .contact-info .contact_nos .fa {
      font-size: 1.6rem;
      color: #557DA9; }
    .contact .contact-info .contact_nos .contact_details {
      padding-left: 15px;
      display: table-cell;
      font-size: 1.4rem;
      font-family: 'Raleway', sans-serif;
      color: #1D1D1D; }
  .contact .contact-info .contact_form {
    float: left;
    width: 100%;
    padding-top: 15px; }
    @media only screen and (min-height: 850px) {
      .contact .contact-info .contact_form {
        padding-top: 25px; } }
    .contact .contact-info .contact_form .form-control {
      float: left;
      border-radius: 0px;
      border: 1px solid #A9A9A9;
      font-family: 'Raleway', sans-serif;
      color: #333;
      width: 49%;
      margin-right: 1%;
      margin-bottom: 10px;
      font-size: 12px;
      height: 28px; }
      @media only screen and (min-height: 850px) {
        .contact .contact-info .contact_form .form-control {
          height: 45px; } }
      .contact .contact-info .contact_form .form-control:nth-child(3) {
        width: 50%;
        margin-right: 0%; }
    .contact .contact-info .contact_form textarea.form-control {
      width: 100% !important;
      height: 60px !important; }
      @media only screen and (min-height: 850px) {
        .contact .contact-info .contact_form textarea.form-control {
          height: 100px !important; } }
    .contact .contact-info .contact_form .submit_btn {
      float: right;
      margin-top: 15px;
      padding: 12px 35px;
      font: normal 12px 'Raleway', sans-serif;
      color: #333;
      background: #28b457;
      border: 1px solid #28b457;
      color: #fff; }
      .contact .contact-info .contact_form .submit_btn:hover {
        background: #28b457; }

.search .navbar-default {
  background: #fff; }
  @media only screen and (min-width: 768px) {
    .search .navbar-default {
      background-color: #fff; } }
  .search .navbar-default .search_box {
    border: 1px solid #ededed; }

.search .wrapper {
  background: #FAFBFB; }
  @media only screen and (min-width: 768px) {
    .search .wrapper {
      height: 100%; } }
  .search .wrapper > .row {
    position: relative;
    height: 100%; }

.search .search-pic {
  background: url("/img/about_main.png") center no-repeat;
  background-size: cover;
  height: 60vh; }
  @media only screen and (min-width: 768px) {
    .search .search-pic {
      z-index: 1;
      position: fixed;
      left: 6px;
      top: 112px;
      width: 50%;
      bottom: 0;
      height: auto; } }
  @media only screen and (min-width: 992px) {
    .search .search-pic {
      width: 33.33333333%; } }

.search .search_contents {
  background: url("/img/search-bg.jpg") center no-repeat;
  background-size: cover;
  padding: 30px 15px; }
  @media only screen and (min-width: 768px) {
    .search .search_contents {
      margin-left: 50%;
      padding: 3rem 3rem;
      min-height: 100%; } }
  @media only screen and (min-width: 992px) {
    .search .search_contents {
      margin-left: 33.33333333%;
      padding: 6rem 8rem; } }
  .search .search_contents h2 {
    color: #567EAA;
    padding-bottom: 20px;
    font-family: 'Raleway';
    font-weight: 700;
    font-size: 3rem;
    color: #090909;
    line-height: 1.6;
    margin-top: 0; }
  .search .search_contents h4 {
    font-size: 2rem;
    color: #567EAA;
    border-top: 1px solid #ebecec;
    padding-top: 2.3rem;
    font-weight: 800; }
  .search .search_contents h4:first-of-type {
    border-top: 0;
    padding-top: 0; }
  .search .search_contents p {
    font-size: 1.6rem;
    font-family: 'Raleway', sans-serif;
    color: #373431;
    line-height: 1.8;
    margin-bottom: 1.8rem;
    font-weight: 300; }
  .search .search_contents .results_amount {
    font-size: 14px;
    color: #737588;
    line-height: 16px; }
  .search .search_contents .media {
    margin: 3rem 0; }
    .search .search_contents .media img {
      max-width: 116px;
      margin-right: 1rem; }
    .search .search_contents .media h4.media-heading {
      font-family: 'Raleway';
      font-weight: 800;
      font-size: 2rem;
      color: #3A8BE5;
      line-height: 1.6;
      margin-top: 0;
      margin-bottom: 0.5rem; }
    .search .search_contents .media p {
      font-family: 'Raleway';
      font-size: 1.3rem;
      color: #373431;
      line-height: 1.8; }
  .search .search_contents .pagination_set .disabled {
    background: #e9ecef;
    color: #a2afbb;
    font: bold 12px 'Raleway', sans-serif;
    text-transform: uppercase;
    padding: 9px 12px;
    border: none; }
  .search .search_contents .pagination_set .next {
    background: #e9ecef;
    color: #000;
    font: bold 12px 'Raleway', sans-serif;
    text-transform: uppercase;
    padding: 9px 12px;
    border: none; }
  .search .search_contents .pagination_set li a {
    border-radius: 0px;
    color: #636C80;
    border: none;
    margin: 1px; }
  .search .search_contents .pagination_set li.active a {
    background: #5279A3;
    color: #fff; }

.testimonials {
  text-align: center;
  background: #fff url("/img/testi_bg.jpg") center center no-repeat;
  border-right: 1px solid #e7ecee;
  padding: 8% 2%; }
  .testimonials .photos img {
    width: 100%; }
  .testimonials .testi_image {
    margin: 0 auto;
    display: inline-block;
    width: 94px;
    height: 94px; }
  .testimonials .img-circle {
    border-radius: 50%; }
  .testimonials .testi_text {
    margin: 5% 12%;
    padding-bottom: 5%;
    font-family: "Georgia", Times, serif;
    font-size: 15px;
    font-style: italic;
    color: #595959;
    line-height: 25px;
    border-bottom: 1px solid #eef2f3; }
    .testimonials .testi_text .testi_com_inv {
      float: right; }
  .testimonials .social li {
    display: inline-block;
    padding: 0% 2%; }
    .testimonials .social li a {
      color: #000;
      font-size: 18px; }
      .testimonials .social li a:hover {
        color: #666;
        text-decoration: none; }

/*==================================================
=            Bootstrap 3 Media Queries             =
==================================================*/
/*==========  Mobile First Method  ==========*/
/* Custom, iPhone Retina */
/* Extra Small Devices, Phones */
/* Small Devices, Tablets */
/* Medium Devices, Desktops */
/* Large Devices, Wide Screens */
/*==========  Non-Mobile First Method  ==========*/
/* Large Devices, Wide Screens */
/* Medium Devices, Desktops */
/* Small Devices, Tablets */
/* Extra Small Devices, Phones */
/* Custom, iPhone Retina */

/*# sourceMappingURL=main.css.map */
